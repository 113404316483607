import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';  // Verwende Routes statt Switch
import { UniqueIDProvider, UniqueIDContext } from './UniqueIDContext.js';
import ToilModule from './modules/ToilModule.js';  // Importiere dein Modul
import BierModule from './modules/BierModule.js';  // Importiere dein Modul
import LuftModule from './modules/LuftModule.js';  // Importiere dein Modul
import OelModule from './modules/OelModule.js';  // Importiere dein Modul
import PdenModule from './modules/PdenModule.js';  // Importiere dein Modul
import B1Module from './modules/B1Module.js';  // Importiere dein Modul
import IterModule from './modules/IterModule.js';  // Importiere dein Modul
import CreateModule from './modules/CreateModule.js';  // Importiere dein Modul
import X1092Module from './modules/X1092Module.js';  // Importiere dein Modul
import BoltModule from './modules/BoltModule.js';  // Import Bolt Module
import CadModule from "./modules/CadModule.js";     // Import Cad Module
import GlycModule from "./modules/GlycModule.js";     // Import Glyc Module
import Co2Module from "./modules/Co2Module.js";     // Import CO2 Module
import FemModule from "./modules/FemModule.js";     // Import Fem Module
import CalculationAssist from "./modules/CalculationAssist.js";     // Import Calculation Assist
import B3Module from "./modules/B3Module.js";
import ModuleFinder from "./modules/ModuleFinder.js";
import MainMenu from './components/MainMenu.js';  // A component for your main menu
import './assets/App.css';  // Importiere das CSS-Design
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <UniqueIDProvider>
      <Router>
        <AppContent />
      </Router>
    </UniqueIDProvider>
  );
}

function AppContent() {
  const { setUniqueID } = useContext(UniqueIDContext);
  const location = useLocation();

  // Fetch unique ID from URL on page load or when the location changes
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uniqueIDFromURL = queryParams.get('code');
    if (uniqueIDFromURL) {
      setUniqueID(uniqueIDFromURL);
    }
  }, [location, setUniqueID]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<MainMenu />} />
        <Route path="/toil" element={<ToilModule />} />
        <Route path="/bier" element={<BierModule />} />
        <Route path="/luft" element={<LuftModule />} />
        <Route path="/oel" element={<OelModule />} />
        <Route path="/pden" element={<PdenModule />} />
        <Route path="/b1" element={<B1Module />} />
        <Route path="/iter" element={<IterModule />} />
        <Route path="/create" element={<CreateModule />} />
        <Route path="/X1092" element={<X1092Module />} />
        <Route path="/bolt" element={<BoltModule />} />
        <Route path="/b3_web" element={<B3Module />} />
        <Route path="/modulefinder" element={<ModuleFinder />} />
        <Route path="/cad" element={<CadModule />} />
        <Route path="/glyc" element={<GlycModule />} />
        <Route path="/co2" element={<Co2Module />} />
        <Route path="/fem" element={<FemModule />} />
        <Route path="/calculationassist" element={<CalculationAssist />} />
      </Routes>
    </div>
  );
}

export default App;


// function App() {
//   return (
//     <Router>
//       <div className="App">
//         <Routes>
//           <Route path="/" element={<MainMenu />} />  {/* Main menu */}
//           <Route path="/toil" element={<ToilModule />} />
//           <Route path="/bier" element={<BierModule />} />
//           <Route path="/b1" element={<B1Module />} />
//           {/* Du kannst hier weitere Routen hinzufügen */}
//           <Route path="/" element={
//             <div>
//               <h1>Willkommen zu deiner App!</h1>
//               <p>Dies ist die Startseite. Gehe zu <a href="/toil">Toil Modul</a>, um das Toil-Modul zu sehen.</p>
//             </div>
//           } />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// export default App;
