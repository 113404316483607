import React from 'react';
import '../assets/FEM.css'; // Custom CSS for the FEM Toolbox portal
import Header from '../assets/Header.js'; // Shared header component

function FEMToolboxPortal() {
  const openFEMPortal = () => {
    window.open('https://fem.lv-global.net', '_blank', 'noopener noreferrer');
  };

  return (
    <div className="container">
      {/* Shared Header */}
      <Header title="FEM Toolbox Portal" />

      {/* Image Section */}
      <div className="image-section text-center mt-4 mb-5">
        <img 
          src="/images/fem_toolbox_overview.jpg"
          alt="FEM Toolbox Overview" 
          className="img-fluid rounded shadow" 
          style={{ maxWidth: '100%', height: 'auto' }}
        />
      </div>

      {/* Portal Description */}
      <div className="description mt-5">
        <h2 className="mb-4">Welcome to the FEM Toolbox Portal</h2>
        <p className="mb-4">
          The FEM Toolbox is an advanced software solution designed to perform Finite Element Analysis (FEA) 
          for pressure vessel design and related engineering applications. This comprehensive platform enables 
          engineers to simulate and analyze complex scenarios, ensuring the reliability and safety of critical components.
        </p>
        <p className="mb-4">
          Built for accessibility, the FEM Toolbox requires no installation. Perform complex calculations directly in your browser and access the tools you need, wherever you are.
        </p>
        <p className="mb-4">
          Click the button below to access the FEM Toolbox Portal and start your Finite Element Analysis journey.
        </p>
      {/* Registration Information Section */}
      <div className="registration-info mt-5 p-4 text-center rounded shadow">
        <h3 className="mb-3">Registration for the FEM Toolbox Portal</h3>
        <p className="mb-3">
          Access is free and allows you to explore the portal&apos;s features and models. Please send us an Email for registration.
        </p>
        <div className="email-instructions mt-4">
          <p>
            <strong>Email Address:</strong> <a href="mailto:info@lv-soft.de">info@lv-soft.de</a>
          </p>
          <p>
            <strong>Subject:</strong> <em>Login FEM Toolbox Portal</em>
          </p>
          <p className="mt-3">
            In your email, please let us know briefly why you would like to use the FEM Toolbox Portal or feel free to ask any questions.
          </p>
        </div>
        <p className="mt-4">
          For productive use, we recommend upgrading to our Premium Access. This provides you with a wider range of models and direct support – with no restrictions on the number of licenses in the free-pool available.
        </p>
        <p className="mt-4">
          Our team will review your request and send you the login details as soon as possible.
        </p>
      </div>

      </div>

      {/* Action Button */}
      <div className="action text-center mt-5">
        <button onClick={openFEMPortal} className="btn btn-primary btn-lg">
          Open FEM Toolbox Portal
        </button>
      </div>
    </div>
  );
}

export default FEMToolboxPortal;
