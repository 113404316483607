import React from "react";
import { useNavigate } from "react-router-dom";  // Ensure this import is correct
import logo from '../assets/logo.png';  // Adjust the path to your logo

const Header = ({ title }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  const handleBackToMenu = () => {
    navigate("/"); // Navigate back to the main menu
  };

  return (
    <div className="header">
      <div className="left-section">
        <button onClick={handleBackToMenu} className="btn btn-secondary mt-3"
        style={{ width: 'auto', padding: '5px 10px' }}
        >
          Back to Main Menu
        </button>
      </div>
      <div className="center-section">
        <h1>{title}</h1>
      </div>
      <div className="logo-container">
        <a
          href="https://lv-soft.net"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={logo} alt="Logo" className="logo" />
        </a>
        <div className="logo-text" style={{ marginTop: '10px', textAlign: 'center', fontSize: '25px' }}>
          Explore more modules at<br />
          <a
            href="https://lv-soft.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            lv-soft.net
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
