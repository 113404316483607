import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaFlask, FaBolt, FaBrain, FaEye, FaCloud, FaCodepen } from 'react-icons/fa'; // Importing icons
import logo from '../assets/logo.png';  // Adjust the path as needed
import './MainMenu.css';  // Importing the CSS file

// Use environment variable for API base URL, fallback to localhost for development
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// Check if running on localhost
const isLocalhost = window.location.hostname === 'localhost';

function MainMenu() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [uniqueID, setUniqueID] = useState(sessionStorage.getItem('uniqueID')); // Fetch from sessionStorage initially
  const location = useLocation();
  const navigate = useNavigate();

  // Add external script dynamically using useEffect
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://cdn.customgpt.ai/js/ai-assistant.js";
    script.defer = true;
    script.setAttribute('p_id', '42858');
    script.setAttribute('p_key', 'c92fab7bc6fd5eb0657ca68a3c8f41f7');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up script when component unmounts
    };
  }, []);

  // Extract unique ID from the URL and store it in sessionStorage
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const uniqueIDFromURL = queryParams.get('code');
    if (uniqueIDFromURL) {
      setUniqueID(uniqueIDFromURL);
      sessionStorage.setItem('uniqueID', uniqueIDFromURL); // Store the unique ID in sessionStorage
      // Send the unique ID to the server to store it in a session
      fetch(`${apiBaseUrl}/set_unique_id?code=${uniqueIDFromURL}`, {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data.message); // Optional: Log for confirmation
        })
        .catch((error) => {
          console.error('Error storing unique ID:', error);
        });
    }
  }, [location]);

  const categories = [
    {
      name: "Strength Calculation",
      icon: <FaBolt />,
      modules: [
        // Conditionally show the "Pressure Vessel Module - B1" only in localhost
        ...(isLocalhost ? [{ name: "Pressure Vessel Module - B1", path: "/b1" }] : []),
        ...(isLocalhost ? [{ name: "Flange dimensions", path: "/X1092" }] : []),
        ...(isLocalhost ? [{ name: "Bolt dimensions", path: "/bolt" }] : []),
        ...(isLocalhost ? [{ name: "Pressure Vessel Module - B3", path: "/b3_web" }] : []),
        ...(isLocalhost ? [{ name: "Iteration Module - Iter", path: "/iter" }] : []),
        { name: "PDEN Module - PDEN", path: "/pden" },
      ],
    },
    {
      name: "Process Engineering",
      icon: <FaFlask />,
      modules: [
        { name: "Thermal Oil Properties Module - Toil", path: "/toil" },
        { name: "Wort & Beer Properties Module - Bier", path: "/bier" },
        { name: "Air Properties Module - Luft", path: "/luft" },
        { name: "Oil Properties Module - Oel", path: "/oel" },
        { name: "Glycol Properties Module - Glyc", path: "/glyc" },
        { name: "CO2 Properties Module - Co2", path: "/co2" },
      ],
    },
    {
      name: "Finite Element Toolbox",
      icon: <FaCodepen />,
      modules: [
        { name: "FE Toolbox - Online Version", path: "/fem" },
        ...(isLocalhost ? [{ name: "FELI - Online Version", path: "/fem" }] : []),
      ],
    },
    {
      name: "AI-Systems",
      icon: <FaBrain />,
      modules: [
        { name: "Module Finder", path: "/modulefinder" },
        { name: "CAD Generator", path: "/cad" },
        { name: "Calculation Assist", path: "/calculationassist" },
        ...(isLocalhost ? [{ name: "Module Creator", path: "/create" }] : []),
      ],
    },
    {
      name: "Cloud-Systems",
      icon: <FaCloud />,
      modules: [
        { name: "Design Atlas 2025", path: "https://cloud.lv-web.net/",external: true, },
      ],
    },
    {
      name: "Demo Version Design Atlas 2025",
      icon: <FaEye />,
      modules: [
        { name: "Demo Design Atlas 2025", path: "https://www.lv-soft.net/trial",external: true, },
      ],
    },
  ];

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  const shortenID = (id) => {
    if (id.length > 20) {
      return `${id.slice(0, 10)}...${id.slice(-10)}`;
    }
    return id;
  };

  const handleLogout = () => {
    setUniqueID(null);
    sessionStorage.removeItem('uniqueID'); // Clear the unique ID from sessionStorage
    fetch(`${apiBaseUrl}/clear_unique_id`, { method: 'POST' })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.message);
      });
    navigate('/');
  };

  return (
    <div className="container">
      <header className="header">
        <div className="header-left">
          <div className="logo-container">
            <a href="https://lv-soft.net" target="_blank" rel="noopener noreferrer">
              <img src={logo} alt="Company Logo" className="logo" />
            </a>
          </div>
          <h1 className="company-name">Lauterbach VT GmbH - Engineering Your Best</h1>
        </div>

        <div className="header-right">
          {uniqueID && (
            <div className="unique-id-container">
              <p>Unique ID: <span title={uniqueID}>{shortenID(uniqueID)}</span></p>
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}

          {/* Add the button for AI Assistant here */}
          <button data-cgpt-ai-assistant data-cgpt-default-button='true' hidden>
            Ask AI
          </button>
        </div>
      </header>

      <h2 style={{ marginTop: '50px', fontSize: '1.5em', color: '#666' }}>
        Please select a category to show available calculation modules
      </h2>

      <div className="category-menu">
        {categories.map((category, index) => (
          <div key={index} className="category-item" onClick={() => handleCategoryClick(category.name)}>
            <div className="category-icon">{category.icon}</div>
            <div className="category-name">{category.name}</div>
          </div>
        ))}
      </div>

      {selectedCategory && (
        <div className="module-container">
          <h3>Modules for {selectedCategory}</h3>
          <div className="module-grid">
            {categories
              .find((category) => category.name === selectedCategory)
              .modules.map((module, idx) => (
                <div key={idx} className="module-tile">
                  <Link to={`${module.path}?code=${uniqueID}`}> {/* Pass unique ID with module path */}
                    <div className="module-icon">
                      {categories.find((category) => category.name === selectedCategory).icon}
                    </div>
                    <div className="module-name">{module.name}</div>
                   </Link>
                </div>
              ))}
           </div>
         </div>
       )}
     </div>
   );
 }

export default MainMenu;
