import React, { useState } from 'react';
import axios from 'axios';
import Header from '../assets/Header.js'; // Import the Header component

// Use environment variable for API URL or fallback to localhost
const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

function GlycModule() {
  const [glycolType, setGlycolType] = useState('');
  const [temperature, setTemperature] = useState('');
  const [volumeFraction, setVolumeFraction] = useState('');
  const [frostTemperature, setFrostTemperature] = useState('');
  const [propertyData, setPropertyData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state

  // Handle form submission
  const handleSubmit = async () => {
    // Clear any previous results or errors
    setError(null);
    setPropertyData(null);

    // Validate inputs
    if (!temperature || !glycolType || (!volumeFraction && !frostTemperature)) {
      setError('Please fill out all required fields.');
      return;
    }

    if (volumeFraction && (volumeFraction < 0 || volumeFraction > 0.6)) {
      setError('Volume fraction must be between 0 and 0.6.');
      return;
    }

    const apiRoute = `${apiBaseUrl}/api/glyc/calculate_properties`;

    try {
      setLoading(true); // Start loading

      // Prepare the payload based on the inputs
      const payload = {
        glycol_type: glycolType,
        temperature: parseFloat(temperature),
      };
      if (volumeFraction) {
        payload.volume_fraction = parseFloat(volumeFraction);
      } else if (frostTemperature) {
        payload.frost_temperature = parseFloat(frostTemperature);
      }

      // Make the POST request to the backend
      const response = await axios.post(apiRoute, payload);

      // Set property data from the response
      setPropertyData(response.data);
    } catch (error) {
      // Improved error handling
      console.error('Error fetching properties:', error);
      const errorMsg =
        error.response?.data?.error ||
        (error.message.includes('Network')
          ? 'Failed to connect to the backend. Please check your network.'
          : 'An unexpected error occurred.');
      setError(errorMsg);
    } finally {
      setLoading(false); // End loading
    }
  };

  // Prepare property data for display
  const formattedData = propertyData
    ? [
        { name: 'Temperature', unit: '°C', value: propertyData.temperature },
        { name: 'Volume Fraction', unit: '', value: propertyData.volume_fraction },
        { name: 'Density', unit: 'kg/m³', value: propertyData.density },
        { name: 'Specific Heat Capacity (cP)', unit: 'J/(kg·K)', value: propertyData.specific_heat_capacity },
        { name: 'Thermal Conductivity (Lambda)', unit: 'W/(m·K)', value: propertyData.thermal_conductivity },
        { name: 'Dynamic Viscosity', unit: 'Pa·s', value: propertyData.dynamic_viscosity },
        { name: 'Frost Temperature', unit: '°C', value: propertyData.frost_temperature },
      ]
    : [];

  return (
    <div className="container">
      <Header title="Glycol Properties Module" />

      {/* Form Section */}
      <div className="form-group">
        <label>Select Glycol Type:</label>
        <select
          className="form-control"
          value={glycolType}
          onChange={(e) => setGlycolType(e.target.value)}
          aria-label="Select Glycol Type"
        >
          <option value="">-- Select Glycol Type --</option>
          <option value="ethylene_glycol">Ethylene Glycol</option>
          <option value="propylene_glycol">Propylene Glycol</option>
        </select>
      </div>

      <div className="form-group">
        <label>Temperature (°C):</label>
        <input
          type="number"
          className="form-control"
          value={temperature}
          onChange={(e) => setTemperature(e.target.value)}
          placeholder="Enter temperature in °C"
          aria-label="Temperature in Celsius"
        />
      </div>

      <div className="form-group">
        <label>Volume Fraction (0-0.60):</label>
        <input
          type="number"
          className="form-control"
          value={volumeFraction}
          onChange={(e) => {
            setVolumeFraction(e.target.value);
            setFrostTemperature(''); // Clear frost temperature if volume fraction is provided
          }}
          min="0"
          max="0.6"
          step="0.01"
          placeholder="Enter volume fraction (0 - 0.6)"
          aria-label="Volume Fraction"
        />
      </div>

      <div className="form-group">
        <label>Frost Temperature (°C):</label>
        <input
          type="number"
          className="form-control"
          value={frostTemperature}
          onChange={(e) => {
            setFrostTemperature(e.target.value);
            setVolumeFraction(''); // Clear volume fraction if frost temperature is provided
          }}
          placeholder="Enter frost temperature in °C"
          aria-label="Frost Temperature"
        />
      </div>

      <button
        onClick={handleSubmit}
        className="btn btn-primary"
        disabled={loading} // Disable button when loading
        aria-label="Submit Button"
      >
        {loading ? 'Loading...' : 'Get Properties Data'}
      </button>

      {/* Error Message */}
      {error && (
        <div className="alert alert-danger mt-3" role="alert">
          {error}
        </div>
      )}

      {/* Results Section */}
      <div id="results" className="mt-4">
        {formattedData.length > 0 ? (
          <table className="table table-bordered mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Unit</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {formattedData.map((property, index) => (
                <tr key={index}>
                  <td>{property.name}</td>
                  <td>{property.unit}</td>
                  <td>
                    {typeof property.value === 'number' ? property.value.toFixed(4) : property.value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          !loading && (
            <p className="text-muted">
              No data available. Please fill out the form and submit.
            </p>
          )
        )}
      </div>
    </div>
  );
}

export default GlycModule;

// import React, { useState } from 'react';
// import axios from 'axios';
// import Header from '../assets/Header.js'; // Import the Header component

// // Use environment variable for API URL or fallback to localhost
// const apiBaseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';

// function GlycModule() {
//   const [glycolType, setGlycolType] = useState('');
//   const [temperature, setTemperature] = useState('');
//   const [volumeFraction, setVolumeFraction] = useState('');
//   const [propertyData, setPropertyData] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false); // Loading state

//   // Handle form submission
//   const handleSubmit = async () => {
//     // Clear any previous results or errors
//     setError(null);
//     setPropertyData(null);

//     // Validate inputs
//     if (!temperature || !glycolType || !volumeFraction) {
//       setError('Please fill out all fields.');
//       return;
//     }

//     if (volumeFraction < 0 || volumeFraction > 0.6) {
//       setError('Volume fraction must be between 0 and 0.6.');
//       return;
//     }

//     const apiRoute = `${apiBaseUrl}/api/glyc/calculate_properties`;

//     try {
//       setLoading(true); // Start loading
//       // Make the POST request to the backend
//       const response = await axios.post(apiRoute, {
//         glycol_type: glycolType,
//         temperature: parseFloat(temperature),
//         volume_fraction: parseFloat(volumeFraction),
//       });

//       // Set property data from the response
//       setPropertyData(response.data);
//     } catch (error) {
//       // Improved error handling
//       console.error('Error fetching properties:', error);
//       const errorMsg =
//         error.response?.data?.error ||
//         (error.message.includes('Network')
//           ? 'Failed to connect to the backend. Please check your network.'
//           : 'An unexpected error occurred.');
//       setError(errorMsg);
//     } finally {
//       setLoading(false); // End loading
//     }
//   };

//   // Real-time validation for volume fraction
//   const validateVolumeFraction = (value) => {
//     if (value < 0 || value > 0.6) {
//       setError('Volume fraction must be between 0 and 0.6.');
//     } else {
//       setError(null);
//     }
//     setVolumeFraction(value);
//   };

//   // Prepare property data for display
//   const formattedData = propertyData
//     ? [
//         { name: 'Temperature', unit: '°C', value: propertyData.temperature },
//         { name: 'Volume Fraction', unit: '', value: propertyData.volume_fraction },
//         { name: 'Density', unit: 'kg/m³', value: propertyData.density },
//         { name: 'Specific Heat Capacity (cP)', unit: 'J/(kg·K)', value: propertyData.specific_heat_capacity },
//         { name: 'Thermal Conductivity (Lambda)', unit: 'W/(m·K)', value: propertyData.thermal_conductivity },
//         { name: 'Dynamic Viscosity', unit: 'Pa·s', value: propertyData.dynamic_viscosity },
//         { name: 'Frost Temperature', unit: '°C', value: propertyData.frost_temperature }, // Added frost temperature
//       ]
//     : [];

//   return (
//     <div className="container">
//       <Header title="Glycol Properties Module" />

//       {/* Form Section */}
//       <div className="form-group">
//         <label>Select Glycol Type:</label>
//         <select
//           className="form-control"
//           value={glycolType}
//           onChange={(e) => setGlycolType(e.target.value)}
//           aria-label="Select Glycol Type"
//         >
//           <option value="">-- Select Glycol Type --</option>
//           <option value="ethylene_glycol">Ethylene Glycol</option>
//           <option value="propylene_glycol">Propylene Glycol</option>
//         </select>
//       </div>

//       <div className="form-group">
//         <label>Temperature (°C):</label>
//         <input
//           type="number"
//           className="form-control"
//           value={temperature}
//           onChange={(e) => setTemperature(e.target.value)}
//           placeholder="Enter temperature in °C"
//           aria-label="Temperature in Celsius"
//         />
//       </div>

//       <div className="form-group">
//         <label>Volume Fraction (0-0.60):</label>
//         <input
//           type="number"
//           className="form-control"
//           value={volumeFraction}
//           onChange={(e) => validateVolumeFraction(e.target.value)}
//           min="0"
//           max="0.6"
//           step="0.01"
//           placeholder="Enter volume fraction (0 - 0.6)"
//           aria-label="Volume Fraction"
//         />
//       </div>

//       <button
//         onClick={handleSubmit}
//         className="btn btn-primary"
//         disabled={loading} // Disable button when loading
//         aria-label="Submit Button"
//       >
//         {loading ? 'Loading...' : 'Get Properties Data'}
//       </button>

//       {/* Error Message */}
//       {error && (
//         <div className="alert alert-danger mt-3" role="alert">
//           {error}
//         </div>
//       )}

//       {/* Results Section */}
//       <div id="results" className="mt-4">
//         {formattedData.length > 0 ? (
//           <table className="table table-bordered mt-4">
//             <thead>
//               <tr>
//                 <th>Name</th>
//                 <th>Unit</th>
//                 <th>Value</th>
//               </tr>
//             </thead>
//             <tbody>
//               {formattedData.map((property, index) => (
//                 <tr key={index}>
//                   <td>{property.name}</td>
//                   <td>{property.unit}</td>
//                   <td>
//                     {typeof property.value === 'number' ? property.value.toFixed(4) : property.value}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           !loading && (
//             <p className="text-muted">
//               No data available. Please fill out the form and submit.
//             </p>
//           )
//         )}
//       </div>
//     </div>
//   );
// }

// export default GlycModule; 

